import { BrowserRouter, Routes, Route } from "react-router-dom"
import UserList from "./assets/component/user/UserList"
import AddUser from "./assets/component/user/AddUser"
import EditUser from "./assets/component/user/EditUser"
import ScheduleList from "./assets/component/scheduleWA/ScheduleList"
import AddSchedule from "./assets/component/scheduleWA/AddSchedule"
import EditSchedule from "./assets/component/scheduleWA/EditSchedule"
function App() {

  return (
    <BrowserRouter>
      <Routes>
      <Route path="users/" element={<UserList />} />
      <Route path="users/addUser" element={<AddUser />}/>
      <Route path="users/editUser/:user" element={<EditUser />}/>
      <Route path="schedulewa/" element={<ScheduleList />} />
      <Route path="schedulewa/addSchedule" element={<AddSchedule />}/>
      <Route path="schedulewa/editSchedule/:id" element={<EditSchedule />}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App
