import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../../../configs/config';

const apiEndPoint = config.apiEndPoint;

const EditSchedule = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Changed to 'id' to reflect schedule ID
    const [name, setName] = useState("");
    const [hour, setHour] = useState("");
    const [minute, setMinute] = useState("");
    const [second, setSecond] = useState("0");
    const [date, setDate] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [no, setNo] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        getScheduleById();
    }, []);

    const handleDateTimeChange = (e) => {
        const datetimeValue = e.target.value;
        const datetime = new Date(datetimeValue);

        setHour(datetime.getHours().toString());
        setMinute(datetime.getMinutes().toString());
        setSecond(datetime.getSeconds().toString()); // Set seconds even though it will likely be 0
        setDate(datetime.getDate().toString());
        setMonth((datetime.getMonth() + 1).toString()); // Months are zero-indexed, so add 1
        setYear(datetime.getFullYear().toString());
    };
    const editSchedule = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(apiEndPoint + `/schedulewa/${id}`, {
                name,
                hour,
                minute,
                second,
                date,
                month,
                year,
                no,
                message
            });
            navigate('/schedulewa');
        } catch (error) {
            console.log(error);
        }
    };
    const formatDateTime = (date, month, year, hour, minute) => {
        // Pad values to ensure correct format
        const yearPad = year.toString();
        const monthPad = month.toString().padStart(2, '0'); // Pastikan bulan memiliki 2 digit
        const datePad = date.toString().padStart(2, '0'); // Pastikan tanggal memiliki 2 digit
        const hourPad = hour.toString().padStart(2, '0'); // Pastikan jam memiliki 2 digit
        const minutePad = minute.toString().padStart(2, '0'); // Pastikan menit memiliki 2 digit

        return `${yearPad}-${monthPad}-${datePad}T${hourPad}:${minutePad}`;
    };

    const getScheduleById = async () => {
        try {
            const response = await axios.get(apiEndPoint + `/schedulewa/${id}`);
            const data = response.data.data[0]; // Assuming the response structure

            setName(data.name);
            setHour(data.hour.toString());
            setMinute(data.minute.toString());
            setSecond(data.second.toString());
            setDate(data.date.toString());
            setMonth(data.month.toString());
            setYear(data.year.toString());
            setNo(data.no);
            setMessage(data.message);
        } catch (error) {
            console.log(error);
        }
    };



    return (
        <div className='columns mt-5 is-centered'>
            <div className='column is-half'>
                <form onSubmit={editSchedule}>
                    <div className='field'>
                        <label className='label'>Name (Bebas)</label>
                        <div className='control'>
                            <input
                                type="text"
                                className='input'
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder='Name'
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>No Whatsapp</label>
                        <div className='control'>
                            <input
                                type="number"
                                className='input'
                                required
                                value={no}
                                onChange={(e) => setNo(e.target.value)}
                                placeholder='628XXXXXXXXXX'
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Message</label>
                        <div className='control'>
                            <textarea
                                className='textarea'
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder='Message'
                            />
                        </div>
                    </div>

                    <div className='field'>
                        <label className='label'>Date and Time</label>
                        <div className='control'>
                            <input
                                type="datetime-local"
                                className='input'
                                value={formatDateTime(date, month, year, hour, minute)}
                                onChange={handleDateTimeChange}
                                placeholder="Date and Time"
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <button type="submit" className='button is-warning'>Edit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditSchedule;
